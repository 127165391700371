import styled from "styled-components";
import media from "./media.js";

export const Title = styled.div`
  font-family: "Bebas Neue";
  font-size: 120px;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  max-width: ${(props) => props.maxWidth};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};

  ${media.md`
    font-size: 72px;
  `}
`;

export const Heading = styled.div`
  font-family: "Bebas Neue";
  font-size: 100px;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  max-width: ${(props) => props.maxWidth};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
`;

export const Description = styled.div`
  font-family: "Inter";
  font-size: 18px;
  line-height: 1.5em;
  color: ${(props) => props.theme.secondary};
  max-width: ${(props) => props.maxWidth};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};

  ${media.md`
    font-size: 16px;
  `};
`;

export const H1 = styled.h1`
  font-family: "Bebas Neue", sans-serif;
  font-size: 4em;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  font-weight: normal;
  margin: 0px;
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
`;

export const H2 = styled.h2`
  font-family: "Bebas Neue", sans-serif;
  font-size: 3em;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  font-weight: normal;
  margin: 0px;
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
`;

export const H3 = styled.h3`
  font-family: "Bebas Neue", sans-serif;
  font-size: 2.6em;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  font-weight: normal;
  margin: 0px;
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
`;

export const H4 = styled.h4`
  font-family: "Bebas Neue", sans-serif;
  font-size: 2em;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  font-weight: normal;
  margin: 0px;
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
`;

export const H5 = styled.h5`
  font-family: "Bebas Neue", sans-serif;
  font-size: 1.6em;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  font-weight: normal;
  margin: 0px;
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
`;

export const H6 = styled.h6`
  font-family: "Bebas Neue", sans-serif;
  font-size: 1.4em;
  color: ${(props) => props.theme.primary};
  line-height: 1.1em;
  font-weight: normal;
  margin: 0px;
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
`;

export const P = styled.p`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  color: ${(props) => props.theme.secondary};
  line-height: 1.5em;
`;

export const A = styled.a`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  color: ${(props) => props.theme.accent};
  line-height: 1.5em;
`;

export const Label = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 12px;
  color: ${(props) => props.theme.secondary};
  line-height: 1.5em;
`;
