import styled from "styled-components";
import media from "../../constants/media.js";
import { Link } from "react-router-dom";

export const Container = styled.div`
  background: ${(props) => props.theme.background};
  padding: 20px;
  border-top: 1px solid #efefef;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: ${(props) => (props.alert ? "80px" : "20px")};
`;

export const Grid = styled.div`
  max-width: 1360px;
  display: grid;
  flex-direction: column;
  grid-template-columns: 200px 200px 200px 200px 200px;
  grid-gap: 20px;
  margin: auto;
  width: 100%;

  ${media.md`
    grid-template-columns: 1fr 1fr  1fr 1fr;
  `};

  ${media.md`
    grid-template-columns: 1fr 1fr;
  `};
`;

export const Icon = styled.img`
  height: 28px;
  width: 28px;
  border: none;
  align-items: center;
  justify-content: center;
`;

export const Row = styled.div`
  display: flex;
  grid-gap: 12px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  width: 100%;
  justify-content: center;
  max-width: 1360px;
`;

export const Game7 = styled.img`
  display: flex;
  height: 16px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;

  ${media.sm`
    flex-direction: column;
    grid-gap: 12px;
    align-items: flex-start;
  `}
`;

export const Copyright = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  text-decoration: none;
  color: ${(props) => props.theme.secondary};
  width: 100%;
  text-align: right;
  display: flex;
  align-items: flex-start;
  grid-gap: 8px;
  margin-top: 20px;
`;

export const Title = styled.a`
  font-family: "Bebas Neue", sans-serif;
  font-size: 24px;
  line-height: 1.5em;
  text-decoration: none;
  color: ${(props) => props.theme.secondary};
  text-transform: uppercase;

  ${media.sm`
      display: inline;
  `}
`;

export const Window = styled.a`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  text-decoration: none;
  color: ${(props) => props.theme.secondary};
  transition: 500ms;
  height: 32px;
  align-items: center;
  display: flex;
  text-align: ${(props) => props.align};

  &:hover {
    color: ${(props) => props.theme.accent};
    transition: 500ms;
    cursor: pointer;
  }
`;

export const Route = styled(Link)`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  text-decoration: none;
  color: ${(props) => props.theme.secondary};
  transition: 500ms;
  height: 32px;
  align-items: center;
  display: flex;
  text-align: ${(props) => props.align};

  &:hover {
    color: ${(props) => props.theme.accent};
    transition: 500ms;
    cursor: pointer;
  }
`;

export const Pending = styled.div`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  text-decoration: none;
  color: ${(props) => props.theme.secondary};
  transition: 500ms;
  height: 32px;
  align-items: center;
  display: flex;
  text-align: ${(props) => props.align};

  &:hover {
    cursor: not-allowed;
  }
`;
