// Imports
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";

// Pages
import Home from "./pages/Home.js";

// Theme
import { light } from "./constants/themes.js";

function App() {
  return (
    <ThemeProvider theme={light}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
