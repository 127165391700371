// Relative Imports
import {
  Container,
  Content,
  Image,
  Copy,
  Heading,
  Title,
  Description,
  ImageContainer,
  Fill,
} from "./styles";
import chars from "../../assets/images/chars.png";

const PanelAvatar = ({
  title,
  description,
  reverse,
  link,
  show,
  src,
  heading,
  button,
}) => {
  return (
    <Container>
      <Content>
        <ImageContainer>
          <Image reverse src={chars} />
        </ImageContainer>
        <Copy reverse>
          <Heading>Now recruiting</Heading>
          <Title>Founding citizens</Title>
          <Description>
            We are seeking Builders and Gamers with an unwavering commitment to
            the betterment of web3 gaming. Embark on a quest to create value,
            shape key decisions, and leave a lasting legacy as you mold the very
            foundations of Game7 Nation.
          </Description>
          {button && <Fill to="/brand">View Assets</Fill>}
        </Copy>
      </Content>
    </Container>
  );
};

export default PanelAvatar;
