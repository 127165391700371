// Relative Imports
import {
  Container,
  Grid,
  Copyright,
  Window,
  Section,
  Title,
  Column,
  Pending,
  Route,
  Game7,
} from "./styles";

import logo from "../../assets/brand/logo-grey.svg";

const Footer = ({ alert }) => {
  return (
    <Container alert={alert}>
      <Column>
        <Grid>
          <Section>
            <Title>Initiatives</Title>
            <Window
              href="https://talent.game7.io"
              rel="noreferrer"
              target="_blank"
            >
              Talent Database
            </Window>
            <Window
              href="https://bounty.game7.io"
              rel="noreferrer"
              target="_blank"
            >
              Referral Bounties
            </Window>
            <Window
              href="https://aid.game7.io"
              rel="noreferrer"
              target="_blank"
            >
              Developer Aid
            </Window>
            <Window
              href="https://game7.io/dev-report-2022"
              rel="noreferrer"
              target="_blank"
            >
              Dev Report 2022
            </Window>
            <Window
              href="https://discourse.bitdao.io/t/g7p-1-game7-bootstrapping-phase/2836"
              rel="noreferrer"
              target="_blank"
            >
              BitDAO Proposal
            </Window>
          </Section>

          <Section>
            <Title>Grants</Title>
            <Window
              href="https://grants.game7.io/technology"
              rel="noreferrer"
              target="_blank"
            >
              Technology
            </Window>
            <Window
              href="https://grants.game7.io"
              rel="noreferrer"
              target="_blank"
            >
              Events
            </Window>
            <Window
              href="https://grants.game7.io"
              rel="noreferrer"
              target="_blank"
            >
              Education
            </Window>
            <Window
              href="https://grants.game7.io"
              rel="noreferrer"
              target="_blank"
            >
              Research
            </Window>
            <Window
              href="https://grants.game7.io"
              rel="noreferrer"
              target="_blank"
            >
              Diversity
            </Window>
          </Section>
          <Section>
            <Title>Social</Title>
            <Window
              href="https://twitter.com/G7_DAO"
              rel="noreferrer"
              target="_blank"
            >
              Twitter
            </Window>
            <Window
              href="https://github.com/G7DAO"
              rel="noreferrer"
              target="_blank"
            >
              Github
            </Window>
            <Window
              href="https://discord.gg/g7dao"
              rel="noreferrer"
              target="_blank"
            >
              Discord
            </Window>
            <Window
              href="https://medium.com/@G7_DAO"
              rel="noreferrer"
              target="_blank"
            >
              Medium
            </Window>
            <Pending>Forum</Pending>
          </Section>

          <Section>
            <Title>About</Title>
            <Window
              href="https://game7.io/media"
              rel="noreferrer"
              target="_blank"
            >
              Media
            </Window>
            <Window
              href="https://game7.io/brand"
              rel="noreferrer"
              target="_blank"
            >
              Brand
            </Window>
            <Window
              href="https://game7.io/newsletter"
              rel="noreferrer"
              target="_blank"
            >
              Newsletter
            </Window>
            <Window
              href="https://game7.io/blog"
              rel="noreferrer"
              target="_blank"
            >
              Blog
            </Window>
            <Window
              href="https://waitlist.game7.io"
              rel="noreferrer"
              target="_blank"
            >
              Waitlist
            </Window>
          </Section>
          <Section>
            <Title>Legal</Title>
            <Window
              href="https://game7.io/terms"
              rel="noreferrer"
              target="_blank"
            >
              Terms
            </Window>
            <Window
              href="https://game7.io/privacy"
              rel="noreferrer"
              target="_blank"
            >
              Privacy
            </Window>
            <Window
              href="https://game7.io/cookies"
              rel="noreferrer"
              target="_blank"
            >
              Cookies
            </Window>
          </Section>
        </Grid>
        <Copyright>
          ©
          <Game7 src={logo} />
        </Copyright>
      </Column>
    </Container>
  );
};

export default Footer;
