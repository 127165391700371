const faunadb = require("faunadb");

const q = faunadb.query

// Instantiate a client
const client = new faunadb.Client({
  secret: process.env.REACT_APP_CF_FAUNADB_SECRET,
  domain: process.env.REACT_APP_CF_FAUNADB_ENDPOINT,
})

/*
  CREATE A NEW USER
  ACTIVE
 */
const createBotUser = async (data) => {
  return client.query(
    q.Create(
      q.Collection('BotOnboarding'),
      { data },
    )
  )
};

const models = {
  createBotUser
};

export default models;