import { useEffect, useState, useMemo } from "react";

import Confetti from "react-confetti";

import Navigation from "../components/navigation";
import HeroContent from "../components/hero-content";
import Footer from "../components/footer";
import PanelAvatar from "../components/panelAvatar";
import avatar from "../assets/images/avatar.png";
import { useSearchParams } from "react-router-dom";
import models from "../models/model.user";


// API & Auth Imports
import { _auth } from "@g7dao/public-project-webhooks";

const Home = () => {
  const [show, showModal] = useState(false);
  const [isLoggedin, setLoggedin] = useState(false);
  const [waitlist, setWaitlist] = useState({
    public_address: "",
    firstName: "",
    lastName: "",
    discordHandle: "",
    discordId: "",
    emailAddress: "",
    applicantType: "",
    description: "",
    referralCode: "",
  });

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const referralCode = searchParams.get("invite");
    const discordId = searchParams.get("id");
    const discordHandle = searchParams.get("handle");

    setWaitlist((prevState) => {
      return { ...prevState, referralCode, discordId, discordHandle };
    });

    _auth.metaMaskClientCheck();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    /* REQUIRED */
    const authToken = _auth.getCookies();
    if (authToken) {
      setLoggedin(true);
    }
  }, []);

  const auth = () => {
    /* REQUIRED */
    _auth.authConnectXps().then(() => {
      const _a = _auth.getAddress();

      if (_a.length > 0) {
        const _d = waitlist
        _d.public_address = _a

        models.createBotUser(_d)
          .catch((e) => console.log(e.message))

        setLoggedin(true);
        localStorage.setItem("address", _a);
        showModal(true);
      }
    });
  };

  const logout = () => {
    /* REQUIRED */
    // Break session and force new signature
    setWaitlist({
      public_address: "",
      firstName: "",
      lastName: "",
      discordHandle: "",
      discordId: "",
      emailAddress: "",
      applicantType: "",
      description: "",
      referralCode: "",
    })
    showModal(false);
    setLoggedin(false);
    localStorage.removeItem("address");
    _auth.deleteCookies();
    _auth.metaMaskClientCheck();
  };

  const openModal = () => {
    logout();
  };

  return (
    <>
      <Navigation />
      {show && (
        <Confetti width={window.innerWidth} height={window.innerHeight} />
      )}
      <HeroContent
        topTitle=""
        title="The Game7 Nation"
        subtitle="Register your wallet address to qualify for Game7 Reputations and Rewards"
        description={``}
        onClick={
          isLoggedin
            ? openModal
            : auth
        }
        buttons={true}
        displayRightButton={true}
        displayLeftButton={false}
        rightButton={
          isLoggedin
            ? "You're Done. Thanks!"
            : "Connect Wallet"
        }
      />
      <PanelAvatar src={avatar} />
      <Footer />
    </>
  );
};

export default Home;
